<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">Nombre</td>
        <td class="left">
          {{ data['PrimaryContactPersonFirstName'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Apellido Paterno</td>
        <td class="left">
          {{ data['PrimaryContactPersonLastName'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Apellido Materno</td>
        <td class="left">
          {{ data['ApellidoMaterno_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Número de documento</td>
        <td class="left">
          {{ data['RUToRUTProvisionalDeContacto_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Nacionalidad</td>
        <td class="left">
          {{ data['PaisDeOrigen_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Email</td>
        <td class="left">
          {{ data['PrimaryContactEmailAddress'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Celular</td>
        <td class="left">
          {{ data['PrimaryPhoneCountryCode'] }}
          {{ data['PrimaryPhoneNumber'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Región</td>
        <td class="left">
          {{ data['Region_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Sede</td>
        <td class="left">
          {{ data['Sede_c_name'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Comuna</td>
        <td class="left">
          {{ data['Comuna_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Año de egreso</td>
        <td class="left">
          {{ data['AnioEstimadoDeEgresoTexto_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Origen UTM</td>
        <td class="left">
          {{ data['utm_source'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Campaña UTM</td>
        <td class="left">
          {{ data['utm_campaign'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Medio UTM</td>
        <td class="left">
          {{ data['utm_medium'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Dispositivo</td>
        <td class="left">
          {{ data['device'] != undefined ? data['device'] : '-' }}
        </td>
      </tr>

      <tr>
        <td class="left">Historial</td>
        <td class="left">
          <ul>
            <li v-for="(link, idx) in formatHistorial(data['historial'])" :key="idx">
              <a :href="link" rel="noopener noreferrer">
                {{ link }}
                <br />
                <br />
              </a>
            </li>
          </ul>
        </td>
      </tr>

      <tr>
        <td class="left">Documento 1</td>
        <td class="left">
          {{ data['documento_0'] }}
        </td>
      </tr>
      
      <tr>
        <td class="left">Documento 2</td>
        <td class="left">
          {{ data['documento_1'] }}
        </td>
      </tr>

      <tr>
        <td class="left">Documento 3</td>
        <td class="left">
          {{ data['documento_2'] }}
        </td>
      </tr>

      <tr>
        <td class="left">Documento 4</td>
        <td class="left">
          {{ data['documento_3'] }}
        </td>
      </tr>

    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = (historial) => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter((word) => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>
