<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">Nombre</td>
        <td class="left">
          {{ data['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Apellido Paterno</td>
        <td class="left">
          {{ data['apellido_paterno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Apellido Materno</td>
        <td class="left">
          {{ data['apellido_materno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Tipo de documento</td>
        <td class="left">
          {{ data['tipo_documento'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Número de documento</td>
        <td class="left">
          {{ data['numero_documento'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Nacionalidad</td>
        <td class="left">
          {{ data['nacionalidad_nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Email</td>
        <td class="left">
          {{ data['email'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Celular</td>
        <td class="left">
          +569
          {{ data['celular'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Teléfono</td>
        <td class="left">
          {{ data['codigo_area'] }}
          {{ data['telefono'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Sede</td>
        <td class="left">
          {{ data['sede_nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Carrera</td>
        <td class="left">
          {{ data['carrera_nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Origen UTM</td>
        <td class="left">
          {{ data['utm_source'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Campaña UTM</td>
        <td class="left">
          {{ data['utm_campaign'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Medio UTM</td>
        <td class="left">
          {{ data['utm_medium'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Dispositivo</td>
        <td class="left">
          {{ data['device'] != undefined ? data['device'] : '-' }}
        </td>
      </tr>

      <tr>
        <td class="left">Historial</td>
        <td class="left">
          <ul>
            <li v-for="(link, idx) in formatHistorial(data['historial'])" :key="idx">
              <a :href="link" rel="noopener noreferrer">
                {{ link }}
                <br />
                <br />
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = (historial) => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter((word) => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>
