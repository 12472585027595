<template>
  <article class="card card--full">
    <div class="card__body font-centered">
      <h3 class="card__title title-small">
        {{ title }}
      </h3>
      <div class="card__table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Carrera</th>
              <th>Consultas</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(details, i) in data[0]['dataset']"
              :key="details"
            >
              <template v-if="i < 10">
                <td class="center">
                  {{ i + 1 }}
                </td>
                <td class="left">
                  {{ details._id }}
                </td>
                <td>{{ details.count != 'nan' ? details.count : '' }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  export default {
    props: {
      title: String,
      data: Object,
    },
    setup(props) {
      return {
        title: props.title,
        data: props.data,
      }
    },
  }
</script>
