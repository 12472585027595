<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Identificador
        </td>
        <td class="left">
          {{ data['identificador'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Tipo Identificador
        </td>
        <td class="left">
          {{ data['tipo_identificador'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['email'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Celular
        </td>
        <td class="left">
          {{ data['celular'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Celular
        </td>
        <td class="left">
          {{ data['fecha'] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = historial => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter(word => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>
