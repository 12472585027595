<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Nombre
        </td>
        <td class="left">
          {{ data['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellido
        </td>
        <td class="left">
          {{ data['apellido'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Tipo de documento
        </td>
        <td class="left">
          {{ data['tipo_identificacion'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Número de documento
        </td>
        <td class="left">
          {{ data['rut'] || data['pasaporte'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['email'].toLowerCase() }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Telefono
        </td>
        <td class="left">
          {{ '+' + data['codigo_telefono'] + ' ' + data['telefono'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Mensaje
        </td>
        <td class="left">
          {{ data['mensaje'] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */

  import { toRefs } from 'vue'
  export default {
    props: {
      data: Object,
    },
    setup(props) {
      const { data } = toRefs(props)
      const formatHistorial = historial => {
        let historialArray = historial.split(/['"\s",']+/)
        let historialSinCorchetes = historialArray.filter(word => word.length > 3)
        return historialSinCorchetes
      }
      return {
        data,
        formatHistorial,
      }
    },
  }
</script>
