<template>
  <table>
    <thead>
      <tr>
        <th style="width: 3%">
          nº
        </th>
        <th style="width: 20%">
          Identificador
        </th>
        <th style="width: 10%">
          Tipo Identificador
        </th>
        <th style="width: 15%">
          Correo
        </th>
        <th style="width: 15%">
          Celular
        </th>
        <th style="width: 15%">
          Fecha
        </th>
        <th style="width: 2%" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(dataDetail, i) in data"
        :key="i"
      >
        <td class="left">
          {{ i + 1 }}
        </td>
        <td class="left">
          {{ dataDetail.identificador }}
        </td>
        <td class="left">
          {{ dataDetail.tipo_identificador }}
        </td>
        <td class="left">
          {{ dataDetail.email.replace('@@', '@').replace('@alumnos.santotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl').toLowerCase() }}
        </td>
        <td class="left">
          {{ dataDetail.celular }}
        </td>
        <td class="left">
          {{ dataDetail.fecha }}
        </td>
        <td
          class="center open-modal"
          @click="updateIdModal(dataDetail._id)"
        >
          <a title=" Ver detalle">
            Ver
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  import { toRefs, inject } from 'vue'
  import moment from 'moment'
  export default {
    props: {
      data: Object,
    },
    setup(props) {
      const { data } = toRefs(props)
      const updateIdModal = inject('updateIdModal')

      return {
        data,
        updateIdModal,
        moment
      }
    },
  }
</script>

<style lang="scss" scoped>
  .gr-3.card--big {
    max-width: 25%;
  }

  .open-modal {
    cursor: pointer;
  }
</style>
