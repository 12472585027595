<template>
 <table>
   <thead>
     <tr>
       <th>Dato</th>
       <th>Valor</th>
     </tr>
   </thead>
   <tbody>
     <tr>
        <td class="left">Nombre</td>
        <td class="left">{{ data['nombre'] }}</td>
      </tr>
      <tr>
        <td class="left">Apellido Paterno</td>
        <td class="left">
          {{ data['apellido_paterno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Apellido Materno</td>
        <td class="left">
          {{ data['apellido_materno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Tipo de documento</td>
        <td class="left">
          {{ data['tipo_documento'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Número de documento</td>
        <td class="left">{{ data['rut'] }}</td>
      </tr>
      <tr>
        <td class="left">Nacionalidad</td>
        <td class="left">
          {{ data['nacionalidad']['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Email</td>
        <td class="left">{{ data['email'] }}</td>
      </tr>
      <tr>
        <td class="left">Celular</td>
        <td class="left">
          {{ data['celular_codigo'] }}
          {{ data['celular_numero'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Teléfono</td>
        <td class="left">
          {{ data['telefono_codigo'] }}
          {{ data['telefono_numero'] }}
        </td>
      </tr>
      <tr>
        <td class="left">Canal</td>
        <td class="left">
          {{ data['canal'] != undefined ? data['canal'] : '-' }}
        </td>
      </tr>
   </tbody>
 </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
 props: {
   data: Object,
 },
 setup(props) {
   const { data } = toRefs(props)
   const formatHistorial = historial => {
     let historialArray = historial.split(/['"\s",']+/)
     let historialSinCorchetes = historialArray.filter(word => word.length > 3)
     return historialSinCorchetes
   }
   return {
     data,
     formatHistorial,
   }
 },
}
</script>
