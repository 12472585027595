<template>
  <div
    class="row row-table"
    v-for="campaing in data['campaing']"
    v-bind:key="campaing"
    style="width: 100%;"
  >
    <article class="card card--full">
      <div class="card__body font-centered">
        <h3 class="card__title title-small">Campañas ({{ campaing['titulo'] }})</h3>
        <div class="card__table">
          <table width="500px">
            <thead>
              <tr>
                <th>#</th>
                <th>Campaña UTM</th>
                <th>Medio UTM</th>
                <th>Origen UTM</th>
                <th class="intercaled">Conversiones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(details, i) of campaing['results']" v-bind:key="details">
                <template v-if="i < 10">
                  <td class="center">{{ i + 1 }}</td>
                  <td class="left">{{ details._id.utm_campaing }}</td>
                  <td class="left">{{ details._id.utm_medium }}</td>
                  <td class="left">{{ details._id.utm_source }}</td>
                  <td class="intercaled">{{ details.count }}</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
export default {
  props: {
    title: String,
    data: Object,
  },
  setup(props) {
    return {
      title: props.title,
      data: props.data,
    };
  },
};
</script>

<style lang="scss" scoped>
.gr-3.card--big {
  max-width: 25%;
}
</style>
