<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Nombre
        </td>
        <td class="left">
          {{ data['nombres'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellido
        </td>
        <td class="left">
          {{ data['apellidos'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Tipo de documento
        </td>
        <td class="left">
          {{ data['tipo_documento'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Número de documento
        </td>
        <td class="left">
          {{ data['rut'] || data['pasaporte'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['email'].toLowerCase() }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Telefono
        </td>
        <td class="left">
          {{ '+' + data['codigo_celular'] + ' ' + data['celular'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Institucion
        </td>
        <td class="left">
          {{ data['institucion'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Sede
        </td>
        <td class="left">
          {{ data['sede'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Carrera
        </td>
        <td class="left">
          {{ data['carrera'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Cedula URL
        </td>
        <td class="left">
          {{ data['cedula_alumno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Cedula Apoderado
        </td>
        <td class="left">
          {{ data['cedula_apoderado'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Contrato
        </td>
        <td class="left">
          {{ data['contrato'] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = historial => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter(word => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>
