<template>
  <article class="card card--full">
    <div class="card__body font-centered">
      <h3 class="card__title title-small">{{ title }}</h3>
      <div class="card__table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>{{ title }}</th>
              <th>Sesiones</th>
              <th>Conversiones</th>
              <th class="intercaled">Sesiones Periodo ant.</th>
              <th class="intercaled">Incremento</th>
              <th>Conversiones Periodo ant.</th>
              <th>Incremento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(details, i) in data['campaign']['dato']" v-bind:key="details">
              <template v-if="i < 10">
                <td class="center">{{ i + 1 }}</td>
                <td class="left">{{ details.nombre }}</td>
                <td>{{ details.sessiones != 'nan' ? details.sessiones : '-' }}</td>
                <td>{{ details.conversion != 'nan' ? details.conversion : '-' }}</td>
                <template v-if="details.pa">
                  <td class="intercaled">
                    {{ details.pa.sessiones != 'nan' ? details.pa.sessiones : '-' }}
                  </td>
                  <td class="intercaled">
                    {{
                      details.pa.incremento_sessiones != 'nan'
                        ? details.pa.incremento_sessiones + '%'
                        : '-'
                    }}
                  </td>
                  <td>
                    {{ details.pa.conversion != 'nan' ? details.pa.conversion : '-' }}
                  </td>
                  <td>
                    {{
                      details.pa.incremento_conversion != 'nan'
                        ? details.pa.incremento_conversion + '%'
                        : '-'
                    }}
                  </td>
                </template>
                <template v-else
                  ><td class="intercaled">-</td>
                  <td class="intercaled">-</td>
                  <td>-</td>
                  <td>-</td></template
                >
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
export default {
  props: {
    title: String,
    data: Object,
  },
  setup(props) {
    return {
      title: props.title,
      data: props.data,
    };
  },
};
</script>

<style lang="scss" scoped>
.gr-3.card--big {
  max-width: 25%;
}
</style>
