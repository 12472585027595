<template>
  <table>
    <thead>
      <tr>
        <th style="width: 3%">
          nº
        </th>
        <th style="width: 20%">
          Fecha
        </th>
        <th style="width: 10%">
          Rut
        </th>
        <th style="width: 15%">
          Nombre
        </th>
        <th style="width: 15%">
          Apellido
        </th>
        <th style="width: 20%">
          E-mail
        </th>
        <th style="width: 2%" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(dataDetail, i) in data"
        :key="i"
      >
        <td class="left">
          {{ i + 1 }}
        </td>
        <td class="left">
          {{ dataDetail.createdAt }}
        </td>
        <td class="left">
          {{ dataDetail.rut }}
        </td>
        <td class="left">
          {{ dataDetail.nombres }}
        </td>
        <td class="left">
          {{ dataDetail.apellidos }}
        </td>
        <td class="left">
          {{
            
            dataDetail.email
              .replace('@@', '@')
              .replace('@alumnos.santotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('alumnos.santotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('alumnos.santotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('v.benitez2@alumnos.santotomas.cv.benitez2@', 'v.benitez2')
              .replace('v.benitez2alumnos.santotomas.c@', 'v.benitez2@')
              .replace(' ', '')
              .replace(/@alumnos.cst|@alumnos.cs|@gmail.com|alumnos.cst|\'|\"/, '')
              .replace('@alumnos.santotomas.c@', '@')
              .replace('@gmail.co', '')
              .replace('@alumnos.c@', '@')
              .replace('alumnos.santormas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('alumnossantotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('@alumnossantotomas.c@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('@alummos.santotomas.c@', '@')
              .replace('@a@', '@')
              .replace('alumno.santotonas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('@alumno@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('alumnos.santotomas@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .replace('@alumnos.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl')
              .toLowerCase()
              
          }}
        </td>
        <td
          class="center open-modal"
          @click="updateIdModal(dataDetail._id)"
        >
          <a title=" Ver detalle">
            Ver
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  import { toRefs, inject } from 'vue'
  export default {
    props: {
      data: Object,
    },
    setup(props) {
      const { data } = toRefs(props)
      const updateIdModal = inject('updateIdModal')

      return {
        data,
        updateIdModal,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .gr-3.card--big {
    max-width: 25%;
  }

  .open-modal {
    cursor: pointer;
  }
</style>
