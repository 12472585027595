<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
          <td class="left">Nombre</td>
          <td class="left">{{ data.PrimaryContactPersonFirstName }}</td>
        </tr>
        <tr>
          <td class="left">Apellido Paterno</td>
          <td class="left">{{ data.PrimaryContactPersonLastName }}</td>
        </tr>
        <tr>
          <td class="left">Apellido Materno</td>
          <td class="left">{{ data.ApellidoMaterno_c }}</td>
        </tr>
        <tr>
          <td class="left">Número de documento</td>
          <td class="left">{{ data.RUToRUTProvisionalDeContacto_c }}</td>
        </tr>
        <tr>
          <td class="left">Email</td>
          <td class="left">{{ data.PrimaryContactEmailAddress }}</td>
        </tr>
        <tr>
          <td class="left">Celular</td>
          <td class="left">{{ data.PrimaryPhoneCountryCode }} {{ data.PrimaryPhoneNumber }}</td>
        </tr>
        <tr>
          <td class="left">Nacionalidad</td>
          <td class="left">{{ data.PaisDeOrigen_c }}</td>
        </tr>
        <tr>
          <td class="left">Tipo de Lead</td>
          <td class="left">{{ data.TipoDeLead_c }}</td>
        </tr>
        <tr>
          <td class="left">Región</td>
          <td class="left">{{ data.RegionColegio_c }}</td>
        </tr>
        <tr>
          <td class="left">Comuna</td>
          <td class="left">{{ data.ComunaColegio_c }}</td>
        </tr>
        <tr>
          <td class="left">Tipo de teléfono</td>
          <td class="left">{{ data.TipoDeTelefono_c }}</td>
        </tr>
        <tr>
          <td class="left">Tipo de correo</td>
          <td class="left">{{ data.TipoDeCorreo_c }}</td>
        </tr>
        <tr>
          <td class="left">Canal de origen</td>
          <td class="left">{{ data.ChannelType }}</td>
        </tr>
        <tr>
          <td class="left">Tipo de origen</td>
          <td class="left">{{ data.TipoDeOrigen_c }}</td>
        </tr>
        <tr>
          <td class="left">Unidad de negocio</td>
          <td class="left">{{ data.UnidadDeNegocio_c }}</td>
        </tr>
        <tr>
          <td class="left">Formulario de origen</td>
          <td class="left">{{ data.FormularioDeOrigen_c }}</td>
        </tr>
        <tr>
          <td class="left">Año estimado de egreso</td>
          <td class="left">{{ data.AnioEstimadoDeEgresoTexto_c }}</td>
        </tr>
        <tr>
          <td class="left">Carrera</td>
          <td class="left">{{ data.Name }}</td>
        </tr>
        <tr>
          <td class="left">Código DEMRE de Programa</td>
          <td class="left">{{ data.CodigoDEMREDePrograma_c }}</td>
        </tr>
        <tr>
          <td class="left">Institución</td>
          <td class="left">{{ data.Institucion_c }}</td>
        </tr>
        <tr>
          <td class="left">Tipo de Programa</td>
          <td class="left">{{ data.TipoDePrograma_c }}</td>
        </tr>
        <tr>
          <td class="left">Modalidad</td>
          <td class="left">{{ data.Modalidad_c }}</td>
        </tr>
        <tr>
          <td class="left">Jornada</td>
          <td class="left">{{ data.Jornada_c }}</td>
        </tr>
        <tr>
          <td class="left">Matrícula</td>
          <td class="left">{{ data.Matricula }}</td>
        </tr>
        <tr>
          <td class="left">Arancel</td>
          <td class="left">{{ data.Arancel }}</td>
        </tr>
        <tr>
          <td class="left">Sede</td>
          <td class="left">{{ data.Sede_c_name }}</td>
        </tr>
        <tr>
          <td class="left">Zona de Admisión</td>
          <td class="left">{{ data.ZonaDeAdmision_c }}</td>
        </tr>

    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = (historial) => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter((word) => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>

