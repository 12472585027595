<template>
  <table>
    <thead>
      <tr>
        <th style="width: 3%">
          nº
        </th>
        <th style="width: 18%">
          Fecha
        </th>
        <th style="width: 12%">
          Rut
        </th>
        <th style="width: 15%">
          Nombre
        </th>
        <th style="width: 13%">
          Apellido
        </th>
        <th style="width: 18%">
          E-mail
        </th>
        <th style="width: 12%">
          Celular
        </th>
        <!-- <th style="width: 7%">
          Nem
        </th> -->
        <th style="width: 2%" />
      </tr>
    </thead>
    <tbody v-if="data != []">
      <tr
        v-for="(dataDetail, i) in data"
        :key="i"
      >
        <td class="left">
          {{ i + 1 }}
        </td>
        <td class="left">
          {{ dataDetail.createdAt }}
        </td>
        <td class="left">
          {{ dataDetail.RUToRUTProvisionalDeContacto_c  }}
        </td>
        <td class="left">
          {{ dataDetail.PrimaryContactPersonFirstName }}
        </td>
        <td class="left">
          {{ dataDetail.PrimaryContactPersonLastName }}
        </td>
        <td class="left">
          {{ dataDetail.PrimaryContactEmailAddress }}
        </td>
        <td class="left">{{ dataDetail.PrimaryPhoneCountryCode + ' ' + dataDetail.PrimaryPhoneAreaCode + ' ' + dataDetail.PrimaryPhoneNumber}}</td>

        <!-- <td class="left">
          {{ dataDetail.nem }}
        </td> -->
        <td
          class="center open-modal"
          @click="updateIdModal(dataDetail._id)"
        >
          <a title=" Ver detalle">
            Ver
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
import { toRefs, inject } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const updateIdModal = inject('updateIdModal')

    return {
      data,
      updateIdModal,
    }
  },
}
</script>

<style lang="scss" scoped>
.gr-3.card--big {
  max-width: 25%;
}

.open-modal {
  cursor: pointer;
}
</style>
