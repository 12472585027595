<template>
 <table>
   <thead>
     <tr>
       <th style="width: 3%">nº</th>
       <th style="width: 20%">Fecha</th>
       <th style="width: 10%">Rut</th>
       <th style="width: 15%">Nombre</th>
       <th style="width: 15%">Apellido</th>
       <th style="width: 20%">E-mail</th>
       <th style="width: 20%">Celular</th>
       <th style="width: 2%"></th>
     </tr>
   </thead>
   
   <tbody>
     <tr v-for="(dataDetail, i) in data" v-bind:key="i">
       <td class="left">{{ i + 1 }}</td>
       <td class="left">{{ moment(dataDetail.fecha).format('YYYY-MM-DD H:mm:ss') }}</td>
       <td class="left">{{ dataDetail.numero_documento }}</td>
       <td class="left">{{ dataDetail.nombre }}</td>
       <td class="left">{{ dataDetail.apellido_paterno }}</td>
       <td class="left">{{ dataDetail.email.replace('@@', '@').replace('@alumnos.santotomas.cl@alumnos.santotomas.cl', '@alumnos.santotomas.cl').toLowerCase() }}</td>
       <td class="left">{{ '+' + dataDetail.codigo_celular + ' ' + dataDetail.celular}}</td>
       <td class="center open-modal" v-on:click="updateIdModal(dataDetail._id)">
         <a title=" Ver detalle">
           Ver
         </a>
       </td>
     </tr>
   </tbody>
 </table>
</template>

<script>
 /* eslint-disable vue/no-dupe-keys */
 import { toRefs, inject } from 'vue'
 import moment from 'moment'
 export default {
   props: {
     data: Object,
   },
   setup(props) {
     const { data } = toRefs(props)
     const updateIdModal = inject('updateIdModal')

     return {
       data,
       updateIdModal,
       moment
     }
   },
 }
</script>

<style lang="scss" scoped>
 .gr-3.card--big {
   max-width: 25%;
 }

 .open-modal {
   cursor: pointer;
 }
</style>
