<template>
  <article class="card card--full">
    <div class="card__body font-centered">
      <h3 class="card__title title-small">
        {{ title }}
      </h3>
      <div class="form-group">
        <label
          for="sedes"
          class="form-control__label"
        >Sedes</label>
        <select
          id="sedes"
          v-model="comboBoxData"
          class="form-control search-term_input"
          name="sedes"
          :value="comboBoxData"
        >
          <template
            v-for="(sede, idx) in SEDES_ST"
            :key="idx"
          >
            <option :value="sede">
              {{ sede }}
            </option>
          </template>
        </select>
      </div>
      <div class="card__table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Carrera</th>
              <th>Zona admisión</th>

              <th>Consultas</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="dataFiltered.length > 0">
              <tr
                v-for="(details, i) in dataFiltered"
                :key="details"
              >
                <template v-if="i < 10">
                  <td class="center">
                    {{ i + 1 }}
                  </td>
                  <td class="left">
                    {{ details._id.carrera.nombre }}
                  </td>
                  <td class="left">
                    {{ details._id.zona_admision.nombre }}
                  </td>
                  <td>{{ details.count != 'nan' ? details.count : '' }}</td>
                </template>
              </tr>
            </template>
            <tr v-else>
              <td />
              <td />
              <td />
              <td>Sin información</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  import { ref, computed, inject, watch, toRefs, onMounted } from 'vue'
  import dataPersistence from '@/services/dataPersistence'

  import { SEDES_ST } from '@/utils'

  export default {
    props: {
      title: String,
      data: Object,
    },
    setup(props) {
      const { title } = toRefs(props)
      const KeyStorage = `TableRpCarrerasPorSedeCB${title.value.split(' ').join('-')}-KeyMatch`
      // Injects
      const matchData = inject('matchData')
      const updateMatchData = inject('updateMatchData')
      // States
      const comboBoxData = ref(matchData.value)
      // Methods
      let dataFiltered = computed(() => {
        return props.data[0].dataset
      })
      // Watch
      watch(comboBoxData, () => {
        dataPersistence.setItem(KeyStorage, comboBoxData.value)
        updateMatchData(comboBoxData.value)
      })
      // OnMounted
      onMounted(() => {
        let pMatch = dataPersistence.getItem(KeyStorage)
        if (pMatch) {
          comboBoxData.value = pMatch
        } else {
          dataPersistence.setItem(KeyStorage, matchData.value)
        }
      })

      return {
        title,
        comboBoxData,
        SEDES_ST,
        dataFiltered,
      }
    },
  }
</script>

<style lang="scss" scoped>
  label {
    margin: 0 10px;
  }

  .form-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .form-control {
    margin-bottom: 0;
  }
</style>
