<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Nombre
        </td>
        <td class="left">
          {{ data['PrimaryContactPersonFirstName'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellido
        </td>
        <td class="left">
          {{ data['PrimaryContactPersonLastName'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Número de documento
        </td>
        <td class="left">
          {{ data['RUToRUTProvisionalDeContacto_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Nacionalidad
        </td>
        <td class="left">
          {{ data['PaisDeOrigen_c'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['PrimaryContactEmailAddress'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Teléfono
        </td>
        <td class="left">
          {{ data['PrimaryPhoneCountryCode'] }}
          {{ data['PrimaryPhoneNumber'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Carrera
        </td>
        <td class="left">
          {{ data['carrera']}}
        </td>
      </tr>
      <tr>
        <td class="left">
          Sede
        </td>
        <td class="left">
          {{ data['sede'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Formulario de origen
        </td>
        <td class="left">
          {{ data['FormularioDeOrigen_c'] }}
        </td>
      </tr>


    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = (historial) => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter((word) => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>

