<template>
  <article class="card card--full">
    <div class="card__body font-centered">
      <h3 class="card__title title-small">
        {{ title }}
      </h3>
      <div class="card__table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>{{ title }}</th>
              <th>Consultas</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(details, i) in data[0]['dataset']"
              :key="details"
            >
              <template v-if="i < 10">
                <td class="center">
                  {{ i + 1 }}
                </td>
                <td class="left">
                  {{ formatAnnos(details._id) }}
                </td>
                <td>{{ details.count != 'nan' ? details.count : '' }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  export default {
    props: {
      title: String,
      data: Object,
    },
    setup(props) {
      const formatAnnos = data => {
        let result = data

        switch (data) {
          case 0:
            result = '4º medio'
            break
          case 1:
            result = '3º medio'
            break
          case 2:
            result = '2º medio'
            break
          case 3:
            result = '1º medio'
            break
          case 4:
            result = 'Egresado'
            break
          default:
            break
        }

        return result
      }

      return {
        title: props.title,
        data: props.data,
        formatAnnos,
      }
    },
  }
</script>
