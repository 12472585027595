<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Nombres
        </td>
        <td class="left">
          {{ data['nombres'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellidos
        </td>
        <td class="left">
          {{ data['apellidos'] }}
        </td>
      </tr>

      <tr>
        <td class="left">
          Tipo de documento
        </td>
        <td class="left">
          {{ data['tipo_documento'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Número de documento
        </td>
        <td class="left">
          {{ data['rut'] || data['pasaporte'] }}
        </td>
      </tr>

      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['email'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Computador
        </td>
        <td class="left">
          {{ data['computador'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Internet en el hogar
        </td>
        <td class="left">
          {{ data['internet_hogar'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Internet en el movil
        </td>
        <td class="left">
          {{ data['internet_movil'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Terminos y condiciones
        </td>
        <td class="left">
          {{ data['terminos_condiciones'] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */

  import { toRefs } from 'vue'
  export default {
    props: {
      data: Object,
    },
    setup(props) {
      const { data } = toRefs(props)
      const formatHistorial = historial => {
        let historialArray = historial.split(/['"\s",']+/)
        let historialSinCorchetes = historialArray.filter(word => word.length > 3)
        return historialSinCorchetes
      }
      return {
        data,
        formatHistorial,
      }
    },
  }
</script>
