<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">
          Nombre
        </td>
        <td class="left">
          {{ data['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellido Paterno
        </td>
        <td class="left">
          {{ data['apellido_paterno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Apellido Materno
        </td>
        <td class="left">
          {{ data['apellido_materno'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Tipo de documento
        </td>
        <td class="left">
          {{ data['tipo_documento'] == 'C' ? 'Carnet de identidad' : 'Otro' }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Número de documento
        </td>
        <td class="left">
          {{ data['rut'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Nacionalidad
        </td>
        <td class="left">
          {{ data['nacionalidad']['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Email
        </td>
        <td class="left">
          {{ data['email'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Celular
        </td>
        <td class="left">
          {{ data['celular_codigo'] }}
          {{ data['celular_numero'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Teléfono
        </td>
        <td class="left">
          {{ data['telefono_codigo'] }}
          {{ data['telefono_numero'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Región
        </td>
        <td class="left">
          {{ data['region']['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Comuna
        </td>
        <td class="left">
          {{ data['comuna']['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Colegio
        </td>
        <td class="left">
          {{ data['colegio']['nombre'] }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Año en curso
        </td>
        <td class="left">
          {{ data['ano_cursado'] }}
        </td>
      </tr>

      <template v-if="data['postulacion'][0]">
        <tr>
          <td class="left">
            Carrera Nº 1 - Sede
          </td>
          <td class="left">
            {{ data['postulacion'][0]['zona_admision']['nombre'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 1 - Jornada
          </td>
          <td class="left">
            {{ data['postulacion'][0]['jornada'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 1 - Carrera
          </td>
          <td class="left">
            {{ data['postulacion'][0]['carrera']['nombre'] }}
          </td>
        </tr>
      </template>
      <template v-if="data['postulacion'][1]">
        <tr>
          <td class="left">
            Carrera Nº 2 - Sede
          </td>
          <td class="left">
            {{ data['postulacion'][1]['zona_admision']['nombre'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 2 - Jornada
          </td>
          <td class="left">
            {{ data['postulacion'][1]['jornada'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 2 - Carrera
          </td>
          <td class="left">
            {{ data['postulacion'][1]['carrera']['nombre'] }}
          </td>
        </tr>
      </template>
      <template v-if="data['postulacion'][2]">
        <tr>
          <td class="left">
            Carrera Nº 3 - Sede
          </td>
          <td class="left">
            {{ data['postulacion'][2]['zona_admision']['nombre'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 3 - Jornada
          </td>
          <td class="left">
            {{ data['postulacion'][2]['jornada'] }}
          </td>
        </tr>
        <tr>
          <td class="left">
            Carrera Nº 3 - Carrera
          </td>
          <td class="left">
            {{ data['postulacion'][2]['carrera']['nombre'] }}
          </td>
        </tr>
      </template>
      <tr>
        <td class="left">
          Origen UTM
        </td>
        <td class="left">
          {{ data['utm_source'] != undefined ? data['utm_source'] : '-' }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Campaña UTM
        </td>
        <td class="left">
          {{ data['utm_campaign'] != undefined ? data['utm_campaign'] : '-' }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Medio UTM
        </td>
        <td class="left">
          {{ data['utm_medium'] != undefined ? data['utm_medium'] : '-' }}
        </td>
      </tr>
      <tr>
        <td class="left">
          Dispositivo
        </td>
        <td class="left">
          {{ data['device'] != undefined ? data['device'] : '-' }}
        </td>
      </tr>

      <tr>
        <td class="left">
          Historial
        </td>
        <td class="left">
          <ul>
            <li
              v-for="(link, idx) in formatHistorial(data['historial'])"
              :key="idx"
            >
              <a
                :href="link"
                rel="noopener noreferrer"
              >
                {{ link }}
                <br>
                <br>
              </a>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="left">
          Canal
        </td>
        <td class="left">
          {{ data['canal'] != undefined ? data['canal'] : '-' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */

  import { toRefs } from 'vue'
  export default {
    props: {
      data: Object,
    },
    setup(props) {
      const { data } = toRefs(props)
      const formatHistorial = historial => {
        let historialArray = historial.split(/['"\s",']+/)
        let historialSinCorchetes = historialArray.filter(word => word.length > 3)
        return historialSinCorchetes
      }
      return {
        data,
        formatHistorial,
      }
    },
  }
</script>
